
/*

    Live search

*/

.livesearch {
    background: $color-white;
    border-radius: 0 0 $radius $radius;
    box-shadow: $shadow;
    display: none;
    position: absolute;
    top: 3rem;
    left: 0; right: 0;
    z-index: 10;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: 12rem;
        overflow: hidden;
        overflow-y: auto;
        border-radius: 0 0 $radius $radius;
        li {
            display: block;
            a {
                display: block;
                padding: 0.5rem 1rem;
                position: relative;
            }
            span {
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: 1rem;
                pointer-events: none;
            }
            span.meta {
                color: $color-textmeta;    
            }
            span.cta {
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
                font-weight: 800;
                color: $color-red;
                @include font-sans;
            }
            &.active, &:hover {
                background-color: rgba($color-gray, 0.5);
            }
        }
        .livesearch__nothing {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            line-height: 2rem;
            color: $color-textmeta;
        }
        li + li {
            border-top: 2px solid rgba($color-gray, 0.5);
        }
    }
}

.livesearch--on {
    position: relative;
    .livesearch {
        display: block;
        box-shadow: $shadow-over;
    }
    > input {
        border-radius: $radius $radius 0 0 !important;
        z-index: 15;
        position: relative;
        box-shadow: $shadow-large;
    }
}