.poppins {
	position: fixed;
	width: 100%;
	box-sizing: border-box;
	top: 0;
	bottom: 0;
	background-color: $color-gray;
	box-shadow: $shadow-over;
	transition: $transition;
	z-index: -1;
	padding: 2rem;
    opacity: 0;
    @include media(tablet) {
        width: 66%;
    }
    @include media(large) {
        width: 50%;
    }
	&.poppins--form {
		padding: 0;
		.form {
			margin-top: 0;
		}
		.poppins__fields {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
            padding: 4rem 4rem 0 4rem;
            .poppins__scroll {
                padding-bottom: 6.5rem;
            }
		}
	}
	h1 {
        margin: 0;
        margin-bottom: 0.5rem;
		line-height: 1.25;
	}
	h2 {
		font-size: 1.25rem;
		margin-top: 0;
	}
	p {
		margin: 0;
		margin-bottom: 1rem;
	}
	.form {
		padding: 0;
		margin-top: 2rem;
		.form__action {
			right: 0;
		}
		.form__progress span {
			display: none !important;
		}
	}
	&.poppins--menu {
		width: auto;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
	}
	&.poppins--nav {
		left: 0;
		right: 0;
		width: 100%;
    }
    &.poppins--share {
        top: 9rem;
        @include media(tablet) {
            top: 7rem;
        }
        bottom: auto;
		right: 50%;
		left: 50%;
        transform: translateX(-50%) !important;
        width: 70%;
        padding: 0;
        .mail__form{
            width: 100%;
            margin-top: 1rem;
            form{
                display: flex;
                flex-direction: column;
                @include media(tablet) {
                    flex-direction: row;
                }
                margin-top: 0;
                > div{
                    flex-grow: 1;
                    &.form__input{
                        flex-grow: 2;
                        padding-right: 0rem;
                        @include media(tablet) {
                            padding-right: 1rem;
                        }
                        &.form__input--button{
                            padding-right: 0rem;
                        }
                        margin-bottom: 1rem;
                    }
                }
                .form__icon{
                    margin-top: .5rem;
                    img{
                        width: 34px;
                        height: auto;
                    }
                }
            }
        }
        .inter-message{
            display: none;
            &.show{
                display: block;
            } 
            .share-msg{
                text-align: center;
                padding: 1rem 0;
                display: block;
            }  
        }  
        ul{
            padding: 0;
            margin: 0;
            height: 6rem;
            transition: $transition;
            &.hide{
                height: 0;
                overflow: hidden;
                transition: $transition;
            }
            li{
                width: 25%;
                float: left;
                list-style: none;
                height: 6rem;
                display: flex;
                align-items: center;
                a{
                    display: block;
                    width: 100%;
                    text-align: center;
                    position: relative;
                    outline: none;
                    span{
                        display: block;
                        font-size: .5rem;
                        font-weight: bold;
                        color: $color-white;
                        &.poppins-share__text{
                            position: absolute;
                            bottom: -1rem;
                            width: 100%;
                            text-align: center;
                            display: none;
                            @include media(tablet) {
                                display: block;
                            }
                        }
                        img{
                            width: 34px;
                            height: auto;
                        }
                    }
                }
                // &:last-child{
                //     width: 100%;
                //     height: 11rem;
                //     padding: 0 1rem;
                //     text-align: center;
                //     @include media(tablet) {
                //         padding: 0 4rem;
                //         height: 6rem;
                //         text-align: left;
                //     }
                //     box-sizing: border-box;
                //     span{
                //         display: inline-block;
                //         margin-right: 1rem;
                //         img{
                //             width: 34px;
                //             height: auto;
                //         }
                //     }
                    
                // }

                &.facebook{
                    background-color: $color-blue;
                    transition: $transition;
                    &:hover{
                        background-color: $color-white;
                        transition: $transition;
                        .poppins-share__text{
                            color: $color-blue;
                        } 
                    }
                }
                &.linkedin{
                    background-color: $color-darkgray;
                    transition: $transition;
                    &:hover{
                        background-color: $color-white;
                        transition: $transition;
                        .poppins-share__text{
                            color: $color-darkgray;
                        } 
                    }
                }
                &.twitter{
                    background-color: $color-lightblue;
                    transition: $transition;
                    &:hover{
                        background-color: $color-white; 
                        transition: $transition; 
                        .poppins-share__text{
                            color: $color-lightblue;
                        } 
                    }
                }
                &.link{
                    background-color: $color-green;
                    transition: $transition;
                    &:hover{
                        background-color: $color-white;
                        transition: $transition; 
                        .poppins-share__text{
                            color: $color-green;
                        } 
                    }
                }
                &.mail{
                    background-color: $color-green;
                }
            }
        }
	}
	&.poppins--right {
		right: 0;
		transform: translateX(2rem);
	}
	&.poppins--left {
		left: 0;
		transform: translateX(-2rem);
	}
	&.poppins--top {
		top: 0;
		bottom: auto;
		transform: translateY(-2rem);
	}
	&.poppins--bottom {
		top: auto;
		bottom: 0;
		transform: translateY(2rem);
	}
	&.poppins--active {
		z-index: 200;
		opacity: 1;
		transform: translateX(0);
		transition: $transition;
    }
    &.poppins--full {
        left: 0;
        right: 0;
        @include media(tablet) {
            width: auto !important;
        }
        @include media(large) {
            width: auto !important;
        }
    }
	
	.poppins__close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		z-index: 200;
		i {
            width: 2rem;
            height: 2rem;
            color: $color-blue;
            line-height: 2rem;
            font-size: 1.5rem;
		}
	}
}

body.poppins__active:not(.poppins__menu) {
	overflow: hidden;
}

.poppins__overlay {
	position: fixed;
	z-index: 190;
	top: 0; left: 0; right: 0; bottom: 0;
    background: rgba($color-darkgray, 0.65);
    &.poppins__overlay--white{
        background: rgba($color-white, 0.65);
    }
}