/*

	Global

*/

html, body {
	height: 100%;
    font-size: 16px;
    @include media(tablet) {
        font-size: 18px;
    }
    @include media(desktop) {
        font-size: 21px;
    }
}

body {
	@include font;
	font-weight: 300;
	line-height: 1.5;
	color: $color-text;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $color-white;
}

// Splash

.splash {
	@include media(tablet) {
		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		background-color: $color-white;
		background-image: url(/img/logo-full.svg);
		background-repeat: no-repeat;
		background-size: 20rem;
		background-position: center;
		z-index: 400;
		transition: $transition;
		&.splash--done {
			opacity: 0;
			background-size: 16rem;
			transition: $transition;
			pointer-events: none;
		}
    }
	
}

// Images

img {
	max-width: 100%;
	height: auto;
}

// Links

a:link, a:visited {
	text-decoration: none;
	color: $color-secondary;
	transition: $transition;
}

p a:link:not(.btn) {
	border-bottom: 3px solid $color-gray;
}

a:hover,
a:hover i:before {
	color: $color-darkgray;
	transition: $transition;
}

// Typography

h1, h2, h3 {
	@include font-sans;
	font-weight: 900;
	line-height: 1;
}

h1 {
	font-size: 2rem; 
	line-height: 1.25;
	text-decoration: underline;
}

h2 {
	font-size: 1.25rem; 
	font-weight: 300;
	line-height: 1.25;
}

h3 {
	font-size: 1rem;
	line-height: 1.5;
}

p {
	line-height: 1.5;
	font-weight: 300;
	color: $color-darkgray;
}

h1, h2, h3, p {
	-moz-font-feature-settings:"kern" 1;
	-moz-font-feature-settings:"kern=1";
	-ms-font-feature-settings:"kern" 1;
	-o-font-feature-settings:"kern" 1;
	-webkit-font-feature-settings:"kern" 1;
	font-feature-settings:"kern" 1;
}

.text--meta {
	color: $color-textmeta !important;
	font-size: 0.85rem !important;
}

.title.meta {
	text-align: center;
	padding-bottom: 1em;
	text-transform: uppercase;
}

.text--center {
	text-align: center;
}

.text--right {
	text-align: right;
}

.flex--align-v-center {
	align-self: center;
}

.flex--align-v-end {
	align-self: flex-end;
}

.list--inline {
	display: inline !important;
	margin: 0; padding: 0;
	margin-left: 0.3rem;
	li {
		display: inline-block;
		margin-right: 0.35rem;
		&:before {
			content: "\25CF";
			margin-right: 0.65em;
		}
	}
}

.color--blue {
	color: $color-blue;
}

.color--green {
	color: $color-green;
}

.color--dark {
	color: $color-text;
}

.color--gray {
	color: $color-darkgray;
}

// Icons

.icon {
	display: inline-block;
}

.icon--green svg {
	path, polygon {
		fill: $color-green;
	}
} 

.icon--blue svg {
	path, polygon {
		fill: $color-blue;
	}
} 

.icon--lightblue svg {
	path, polygon {
		fill: $color-lightblue;
	}
} 


.icon--dark svg {
	path, polygon {
		fill: $color-darkgray;
	}
} 

.icon--light svg {
	path, polygon {
		fill: $color-textmeta;
	}
}

.icon--white svg {
	path, polygon {
		fill: $color-white;
	}
}

.material-icons--round {
	width: 3rem;
	height: 3rem;
	text-align: center;
	border-radius: 1.5rem;
	line-height: 3rem;
	background-color: $color-white;
	box-shadow: $shadow;
	i {
		vertical-align: middle;
	}
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
from {
	-webkit-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
}
to {
	-webkit-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	transform: rotate(360deg);
}
}
@keyframes rotating {
from {
	-ms-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
}
to {
	-ms-transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-webkit-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	transform: rotate(360deg);
}
}
.material-icons--spin {
	-webkit-animation: rotating 2s linear infinite;
	-moz-animation: rotating 2s linear infinite;
	-ms-animation: rotating 2s linear infinite;
	-o-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;
}

.material-icons--hide {
	display: none;
}

// Profile photo

.profile {
	border-radius: 100%;
	overflow: hidden;
	margin-bottom: 2rem;
	img {
		display: block;
	}
}

// Screen reader only

.sr-only {
	visibility: hidden;
	position: absolute;
	left: -9999em;
	overflow: hidden;
	height: 0;
}

// Credit

.credit {
	bottom: 3rem;
    right: 4rem;
    font-size: 0.85rem;
	opacity: 0.5;
	position: absolute;
}

// Tooltipster 

.tooltipster-base {
	z-index: 190 !important;
	//background: $color-white;
	.tooltipster-box {
		padding: 1rem;
		border: 0 !important;
		border-radius: 0 !important;
		background: $color-white !important;
		box-shadow: $shadow;
	}
	.tooltipster-content {
		color: $color-textmeta !important;
		padding: 0;
		line-height: 1.5;
		font-size: 0.85rem;
	}
}

// Inline list for solution pages

.applications {
	font-size: 0.85rem !important;
}

// Hubspot chat bot

.widget-app-container {
	padding: 0 2.5rem 2.5rem 0;
}