/*

	Menu

*/

.page .menu {
	height: 4rem;
	padding: 0 2rem;
	z-index: 100;
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	@include media(tablet) {
		display: flex;
    }
	@include media(desktop) {
		padding: 0rem 4rem;
    }
	.menu__logo {
		height: 4rem;
		line-height: 4rem;
		width: 38px;
		@include media(desktop) {
			width: 178px;
        }
        .menu__logo--logo{
            display: none;
            padding-left: .4rem;
            @include media(desktop) {
                display: inline;
            }
        }
		img {
			vertical-align: middle;
			position: relative;
			top: -2px;
		}
	}
	.menu__nav {
		width: 100%;
		height: 0;
		padding: 2rem;
		overflow: hidden;
		visibility: hidden;
		position: absolute;
		@include media(tablet) {
			width: calc(100% - 38px);
			overflow: initial;
			height: auto;
			padding: 0;
			visibility: visible;
			position: relative;
		}
		@include media(desktop) {
			width: calc(100% - 178px);
		}
		ul {
			margin: 0 0 0 0.5rem;
			padding: 0;
			list-style: none;
			li {
				float: left;
				line-height: 4.1rem;
				position: relative;
				a {
					font-weight: 800;
					padding: 0 0.5rem;
					color: $color-white;
					display: inline-block;
					&:hover {
						color: $color-blue;
						path {
							fill: $color-white !important;
							transition: $transition;
						}
					}
				}
				img {
					vertical-align: sub;
				}
				svg {
					display: inline-block;
					vertical-align: middle;
					top: -2px;
					position: relative;
					path {
						transition: $transition;
					}
				}
				&.current {
					a {
						color: $color-green;
					}
					&:before {
						content: " ";
						position: absolute;
						border-bottom: 3px solid $color-green;
						left: 0.5rem; right: 0.5rem;
						bottom: 0.9rem;
					}	
				}
				&.active {
					a {
						color: $color-white;
					}
				}
			}
			.nav__login, .nav__support, .nav__blog {
				float: right;
            }
            .nav__login {
                span{
                    display: inline;
                    @include media(tablet){
                        display: none;
                    }
                    @include media(desktop){
                        display: inline;
                    }
                    @include media(large){
                        display: inline;
                    }
                }
                &:hover svg path {
					fill: $color-secondary !important;
				}
            }
		}
	}
	.menu__button {
		display: inline-block;
		line-height: 4rem;
		padding: 0 2rem;
		position: absolute;
		top: 0;
		right: 0;
		width: 1.5rem;
		img {
			position: relative;
			top: 1px;
		}
		@include media(tablet) {
			display: none;
		}

	}
}

// Mobile menu

body.menu--on .menu .menu__nav {
	position: fixed;
	overflow: auto !important;
	height: auto;
	width: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	background-color: $color-blue;
	padding: 2rem;
	box-sizing: border-box;
	transition: height 200ms $bezier;
	visibility: visible;
	z-index: 99999;
	ul {
		margin: 0;
		li {
			float: none !important;
			a {
				font-size: 1.25rem;
			}
			&:before {
				left: auto !important;
				right: 0.5rem;
				bottom: 2rem !important;
				width: 3rem;
			}
		}
	}
}



// Menu scroll

.page.menu__up {
	.menu {
		transition: $transition;
		transform: translateY(0);
		position: fixed;
		opacity: 1;
	}
}

.page.menu__down {
	.menu {
		transform: translateY(-2rem);
		overflow: hidden;
		opacity: 0;
	}
}

// Chat button

.menu__chat {
	width: 3rem;
	height: 3rem;
	background-color: $color-blue;
	line-height: 3rem;
	border-radius: 3rem;
	opacity: 0;
	position: fixed;
	text-align: center;
	bottom: 2rem;
	right: 2rem;
	box-shadow: $shadow;
	z-index: 210;
	transform: translateX(2rem);
	transition: $transition;
	border: 1px solid rgba(255,255,255,0.1);
	@include media(tablet) {
		bottom: 2.5rem;
		right: 2.5rem;
	}
	.material-icons {
		display: none;
		vertical-align: text-top;
		color: $color-white;
		font-size: 1.5rem;
    }
	&.start {
		opacity: 1;
		transform: translateX(0);
		transition: $transition;
	}
	&.pulse {
		-webkit-animation: pulsate 400ms ease-out;
		-webkit-animation-iteration-count: 3;
	}
	&.active {
		.icon {
			display: none;
		}
		.material-icons {
			display: inline-block;
		}
	}
	&:hover {
		box-shadow: $shadow-over;
		transform: translateZ(0);
		transform: scale(1.052);
		transition: $transition;
	}
	svg {
		vertical-align: middle;
	}
	&:after {
		content: " ";
		width: 27rem;
		height: 27rem;
		position: absolute;
		display: block;
		bottom: -3rem;
		right: -3rem;
		z-index: 0;
		pointer-events: none;
		background-image: radial-gradient(
			circle at bottom right,
			rgba($color-darkgray, 0.15) 0%,
			rgba($color-darkgray, 0) 50%
		);
    }
    &.menu__chat--share {
		opacity: 1;
		transform: translateX(0);
		left: 2.5rem;
		right: auto;
		width: auto;
		padding: 0 1.5rem;
		font-weight: 800;
		color: #fff;
		text-transform: uppercase;
		.label--close {
			display: none;
		}
        &:after{
            background-image: none;
		}
		&.active {
			.label--share {
				display: none;
			}
			.label--close {
				display: inline-block;
			}
		}
    }
}


body.poppins__active .menu__chat, body.menu--on  .menu__chat {
	display: none;
}

body.menu--on  .menu__chat {
	display: none !important;
}

body.poppins__active.poppins__menu .menu__chat {
	display: block;
}

@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(1, 1); opacity: 0.95;}
    33% {opacity: 1.0;}
	66% {-webkit-transform: scale(1.2, 1.2); opacity: 0.95;}
	100% {-webkit-transform: scale(1, 1); opacity: 1;}
}

// Set the menu to be above overlay when active

body.page.poppins__nav {
	.menu {
		z-index: 195;
	}
}

// Menu mega-menu for solutions

#poppins-solutions {
	padding: 0;
	bottom: 0;
	overflow: auto;
	.col-group {
		margin: 0;
		@include media(tablet) {
			display: flex;
		}
		> div {
			padding: 2rem 0 0 0;
			@include media(tablet) {
				padding: 0 0 2rem 0;
			}
		}
		> div:first-of-type {
			@include media(tablet) {
				border-right: 3px solid $color-white;
			}
		}
	}
	h2 {
		padding: 1rem 2rem 0 2rem;
		font-size: 0.85rem;
		color: $color-textmeta;
		margin: 0;
		font-weight: 800;
		@include media(tablet) {
			padding: 2rem 4rem 0 4rem;
		}
	}
	@include media(tablet) {
		top: 4rem;
		bottom: auto;
		overflow: initial;
	}
	a.poppin__item {
		display: block;
		padding: 1rem 2rem;
		position: relative;
		h3, p {
			font-size: 0.85rem;
			padding: 0;
			margin: 0;
			transition: $transition;
		}
		.icon {
			float: right;
			margin-left: 1rem;
			position: relative;
			top: 2px;
			transition: $transition;
		}
		@include media(tablet) {
			padding: 1rem 4rem;
		}
		
		&:hover {
			transition: $transition;
			transform: scale(1.035);
		}
	}
    .color__blue {
        h3 {
            color: $color-blue;
		}
    }
    .color__green {
        h3 {
            color: $color-green;
		}
    }
    .color__dark {
        h3 {
            color: $color-text;
		}
    }
}


// Chat poppin

#poppins-chat {
	padding-top: 0;
	padding-left: 2rem;
	padding-right: 2rem;
	padding-bottom: 5rem;
	background: transparent;
	box-shadow: none;
	background-image: radial-gradient(
		circle at bottom right,
		rgba($color-darkgray, 0.65) 0%,
		rgba($color-darkgray, 0) 50%
    );
	@include media(tablet) {
		width: 22rem;
		padding-left: 0;
		padding-right: 2.5rem;
	}
	.form {
		margin-top: 1rem;
	}
}

// Chat bubbles

.chat {
	margin-bottom: 2.5rem;
	.chat__msg {
		padding: 1rem;
		font-size: 0.85rem;
		margin-left: 4rem;
		margin-bottom: 1rem;
		background-color: $color-white;
		border-radius: 4px;
		box-shadow: $shadow;
		p {
			margin: 0;
			line-height: 1.1rem;
		}
	}
	.form {
		.form__input {
			margin-bottom: 1rem !important;
		}
		button {
			background-color: $color-textmeta;
			pointer-events: none;
			i {
				color: $color-white;
			}
		}
		.form__completed {
			opacity: 0;
			height: 1px;
			overflow: hidden;
			display: block !important;
			position: absolute;
			pointer-events: none;
			text-align: left !important;
			margin: 2.5rem 0 !important;
		}
		&.form--done {
			button {
				background-color: $color-green;
				pointer-events: all;
				i {
					color: $color-darkgray;
				}
			}
		}
		&.form--completed {
			.form__input, .chat__msg {
				display: none;
			}
			.form__completed {
				opacity: 1;
				height: auto;
				overflow: auto;
				position: relative;
				pointer-events: all;
			}
		}
	}
}

// Login menu
#poppins-login {
	bottom: 0;
	left: 0;
	top: -200rem;
	padding: 0;
	@include media(tablet) {
		padding: 4rem 0 0 0;
		z-index: 90;
		bottom: auto;
		left: auto;
    }
    &.poppins--active{
        top: 0;
    }
	background-color: $color-white;
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			a {
				display: block;
				padding: 1rem 2rem;
				font-weight: 800;
			}
		}
		li + li {
			a {
				border-top: 3px solid $color-gray;
			}
		}
	}
}