/*

	Config

*/

// Colors

$color-green: #18C153;
$color-red: #EF4839;
$color-blue: #0066B2;
$color-yellow: #FFDE4F;

$color-lightblue: #00ADEE;

$color-darkgray: #231F20;
$color-primary: $color-red;
$color-secondary: $color-darkgray;
$color-white: #FFFFFF;
$color-gray: #F7F2EA;

$color-text: $color-darkgray;
$color-textmeta: #A29D99;

// Shadow

$shadow: 0 2px 8px 0 rgba(#000, 0.1);
$shadow-large: 0 4px 64px 0 rgba(#000, 0.1);
$shadow-over: 0 16px 128px 0 rgba(#000, 0.15);

// Radius

$radius: 4px;

// Transition

$bezier: cubic-bezier(0.400, 0.150, 0.000, 0.975);
$transition: all 250ms $bezier;

// Break-points

@mixin media($point) {
    @if $point == mobile {
        @media (max-width: $screenTablet) { @content; }
    }
    @else if $point == tablet {
        @media (min-width: $screenTablet) { @content; }
    }
    @else if $point == desktop {
        @media (min-width: $screenDesktop) { @content; }
    }
    @else if $point == large {
        @media (min-width: $screenLarge)  { @content; }
    }
}

// Fonts

@mixin font {
	font-family: "Merriweather", Helvetica, Arial, sans-serif;
}

@mixin font-sans {
	font-family: "Merriweather Sans", Helvetica, Arial, sans-serif;
}
