.logo {
    margin-bottom: 2rem;
    display: block;
}

// Chat button

.fb_dialog {
    z-index: 190 !important;
}

.fb-customerchat, .fb_dialog {
    @include media(mobile) {
        display: none;
    }
}

// Register 

.poppins  {
    background: $color-gray;
    &.poppins--form .poppins__fields {
        padding: 0;
        .poppins__scroll {
            padding-bottom: 0 !important;
        }
    }
    &.poppins--form .poppins__fields .form {
        padding-top: 2rem;
        @include media(desktop) {
            padding-top: 4rem;
        }
    }
    &.poppins--form .poppins__fields fieldset {
        padding: 0 2rem 0 2rem;
        @include media(desktop) {
            padding: 0 4rem 0 4rem;
        }
    }
    &.poppins--form .poppins__fields fieldset.active {
        min-height: calc(100vh - 2rem);
        @include media(desktop) {
            min-height: calc(100vh - 4rem);
        }
        .col-group {
            min-height: calc(100vh - 7rem);
            @include media(desktop) {
                min-height: calc(100vh - 9rem);
            }
        }
        padding-bottom: 5rem;
        box-sizing: border-box;
    }
}

.section--register {
    height: 100vh;
    .container {
        box-sizing: border-box;
        height: 100vh;
        .col-group {
            align-items: stretch;
            height: 100%;
        }
    }
    h1 {
        margin-bottom: 0.5rem;
        @include media(mobile) {
            font-size: 1.5rem;
        }
    }
    p {
        margin-top: 0;
    }
    .start.col-6 {
        @include media(tablet) {
            padding-right: 2rem !important;
        }
        @include media(desktop) {
            padding-right: 4rem !important;
        }
    }
    .cover {
        position: relative;
        @include media(tablet) {
            height: 100%;
        }
        @include media(mobile) {
            min-height: 25vh;
            margin-bottom: 4rem;
            width: 100%;
        }
        .w {
            background: url(/img/cover-4.png);
            background-size: cover;
            background-position: bottom center;
            border-radius: 4px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 1rem;
            right: 1rem;
            
        }
    }
    .start {
        padding-top: 1rem;
        a {
            display: block;
        }
    }
}

// Register poppin

#register-form {
    .form {
        h1 {
            word-break: break-all;
            @include media(mobile) {
                font-size: 1.5rem;
            }
        }
        h2 {
            font-size: 1rem;
            font-weight: 800;
        }
        .form__step {
            line-height: 1rem;
            padding-top: 1.5rem;
            @include media(mobile) {
                display: none;
            }
        }
        .form__action {
            padding: 1rem 2rem 1rem 2rem;
            @include media(desktop) {
                padding: 1rem 4rem 2rem 4rem
            }
        }
        fieldset {
            .col-6:first-child {
                @include media(desktop) {
                    padding-right: 4rem;
                }
            }
            .col-6:last-child {
                @include media(desktop) {
                    padding-left: 4rem;
                }
            }
        }
        .address {
            .address__item {
                display: none;
                &.active {
                    display: block;
                }
                &.selected .address__selected {
                    display: block;
                }
            }
            .address__nav {
                a {
                    display: block;
                    @include font-sans;
                    color: $color-textmeta;
                    font-weight: 800;
                    line-height: 2rem;
                    &:hover {
                        color: $color-primary;
                    }
                    &.active {
                        display: none;
                    }
                }
            }
            .address__selected {
                padding: 1rem;
                display: none;
                border-radius: $radius;
                margin-bottom: 2rem;
                background: $color-primary;
                position: relative;
                span {
                    display: block;
                    color: $color-white;
                    &.street {
                        font-weight: bold;
                    }
                    &.meta {
                        opacity: 0.5;
                    }
                }
                span.cta {
                    display: none;
                }
                i {
                    float: right;
                    color: $color-white;
                }
            }
        }
    }
    .poppins__split {
        position: absolute;
        top: 0;
        bottom: 0;
        background: #ffffff;
        width: 50%;
        z-index: -1;
        display: none;
        @include media(tablet) {
            display: block;
        }
    }
}

#register-success {
    display: none;
}

body.success {
    #register-form, .section--register {
        display: none;
    }
    #register-success {
        background: url(/img/thanks.jpg);
        background-size: cover;
        overflow: auto;
        display: block;
        opacity: 1;
        transition: $transition;
        z-index: 999999;
        padding-bottom: 26rem;
        background-attachment: local;
        .thanks {
            padding: 4rem 0;
            text-align: center;
            .logo-simple {
                margin-bottom: 1rem;
            }
            .text-meta {
                color: $color-textmeta;
                margin: 0;
            }
            h1 {
                padding-bottom: 1rem;
                font-size: 2.5rem;
                @include media(mobile) {
                    font-size: 1.5rem;
                }
                span {
                    display: block;
                    font-size: 5rem;
                }
            }

        }
    }
}


body.error {
    #register-form, .section--register {
        display: none;
    }
    #register-error {
        background-size: cover;
        overflow: auto;
        display: block;
        opacity: 1;
        transition: $transition;
        z-index: 999999;
        padding-bottom: 26rem;
        background-attachment: local;
        text-align: center;
        h1 {
            @include media(mobile) {
                font-size: 1.5rem;
            }
        }
       .text-meta {
           color: $color-textmeta;
       }
       p a {
           color: $color-red;
           border-color: $color-white;
       }
    }
}