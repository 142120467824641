
/*

	Sections

*/

.section {
	padding: 0;
	position: relative;
}

.section .container {
	padding: 2em 2rem;
    max-width: none;
    @include media(desktop) {
        padding: 4rem 4rem;
    }
}

/*

	Card

*/

.card {
    position: relative;
    @include media(tablet) {
        min-height: 50vw;
    }
	p {
		line-height: 1.5;
	}
	p.category {
		margin: 0;
		font-size: .85rem;
		color: $color-blue !important;
		text-transform: uppercase;
		font-weight: 800;
	}
    blockquote{
        font-size: 1.2rem;
        padding-top: .5rem;
        p{
            color: $color-blue;
            line-height: 1.2;
            strong{
                font-weight: 100;
            }
        } 
    }
	strong {
		font-weight: 700;
	}
	h1 {
		margin-bottom: 1.5rem;
	}
	h2 {
		margin: 0;
	}
	ul {
		padding: 0;
	}
	.container {
		position: relative;
		z-index: 10;
	}
	// Card colors
	&.card--white {
		background-color: $color-white;
		h1 {
            color: $color-primary;
            &.blue{
                color: $color-blue;
            }
		}
	}
	&.card--light {
		background-color: $color-gray;
		h1 {
            color: $color-primary;
            &.blue{
                color: $color-blue;
            }
		}
	}
	&.card--red {
		background-color: $color-red;
		h1 {
			color: $color-primary;
		}
	}
	&.card--green {
		background-color: $color-green;
		h1, h3 {
			color: $color-white;
		}
	}
	&.card--blue {
		background-color: $color-blue;
		h1, h2, h3, p {
			color: $color-white;
		}
	}
	&.card--yellow {
		background-color: $color-yellow;
		h1, h3 {
			color: $color-white;
		}
	}
	&.card--dark {
		background-color: $color-darkgray;
		color: $color-white;
		h1 {
			color: $color-primary;
		}
		p {
			color: $color-white;
		}
		&.card--gridlines:before, &.card--gridlines:after {
			border-color: mix($color-darkgray, $color-white, 90%);
		}
	}
	// Card grid
	&.card--grid {
		.container {
			padding: 0;
		}
		.col-group {
			@include media(tablet) {
				display: flex;
				align-items: stretch;
				flex-flow: row wrap;
			}
			margin-left: 0;
			margin-right: 0;
			padding: 0;
		}
		.card__col {
            padding: 2rem !important;
            @include media(tablet) {
                min-height: 50vh;
				position: relative;
				padding: 4rem 2rem !important;
			}
			@include media(large) {
				padding: 4rem !important;
			}
        }
		h1 {
			font-size: 1.25rem;
		}
		p {
			font-size: 1rem;
			font-weight: 500;
		}
		.card__col--light {
			background: $color-gray;
		}
		.card__col--yellow {
			background: $color-yellow;
		}
		.card__col--green {
			background: $color-green;
			h1 {
				color: $color-text;
			}
		}
		.card__col--red {
			background: $color-red;
		}
		.card__col--dark {
			background: $color-darkgray;
			color: $color-white;
			p {
				color: $color-white;
			}
		}
		.card__col--blue {
			background: $color-blue;
			p {
				color: $color-white;
			}
        }
        @include media(tablet) {
            .card__col--center .col-content {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
            }
            .card__col--bottom .col-content {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding-bottom: 0.5rem;
            }
        }
	}
    // Grid lines
	&.card--gridlines {
		@include media(tablet) {
			&:after {
				content: " ";
				position: absolute;
				left: calc(50% - 1.535rem);
				border-left: 3px solid #fff;
				height: 100%;
				top: 0;
				z-index: 10;
			}

			&:before {
				content: " ";
				position: absolute;
				left: 0;
				border-top: 3px solid #fff;
				width: calc(100% - 3rem);
				top: calc(50% - 0.035rem);
				z-index: 10;
			}
		}
		&.card--center .container {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			right: 3rem;
			.col-content {
				padding-top: 0;
			}
		}
		&.card--bottom .container {
			position: absolute;
			bottom: 0%;
			left: 0;
			right: 3rem;
		}
	}
	// Card graphics
	.card__graphic {
		@include media(tablet) {
			background-repeat: no-repeat;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 5;
			&.card__graphic--topleft {
				background-position: top left;
			}
			&.card__graphic--topright {
				background-position: top right;
			}
			&.card__graphic--bottomleft {
				background-position: bottom left;
			}
			&.card__graphic--bottomright {
				background-position: bottom right;
			}
		}
	}
	// Card adaptive: No min height
	&.card--adaptive {
		min-height: auto;
	}
	&.card--square {
		@include media(tablet) {
			min-height: 100vw;
		}
    }
    // Cards with flexbox: Same height for each one 
    &.card--flex{
        .col-group{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            .cards__title{
                flex-basis: 100%;
            }
        }
    }
}

// Inline cards

.cards {
	display: flex;
	flex-wrap: wrap;
	.cards__card {
        margin-bottom: 2rem;
		p {
			font-size: 0.85rem;
			color: $color-text;
		}
		h3 {
			color: $color-text;
		}
		h2 {
			font-weight: bold;
			font-size: 1rem;
		}
		.col-content {
			padding: 2rem;
			background-color: $color-white;
			width: 100%;
			box-shadow: $shadow-large;
			border-radius: 4px;
			height: 100%;
			position: relative;
			padding-bottom: 4rem;
			.actions {
				position: absolute;
				bottom: 0;
				right: 0;
				padding: 0 2rem 2rem 2rem;
				.btn--link {
					padding: 0;
        			line-height: 1rem;
				}
			}
        }
		// Colors
		&.cards__card--green {
			h3 {
				color: $color-green;
			}
		}
		&.cards__card--blue {
			h3 {
				color: $color-blue;
			}
		}
		&.cards__card--gray {
			h3 {
				color: $color-textmeta;
			}
		}
		&.cards__card--article {
			h2 {
				font-weight: 800;
				line-height: 1.25;
				color: $color-darkgray;
			}
			.col-content {
				padding-top: 10rem !important;
				padding-bottom: 2rem !important;
				position: relative;
			}
			p.category {
				position: absolute;
				top: 2rem;
				left: 2rem;
				line-height: 1.5;
				span.text--meta {
					display: block;
					font-weight: 400;
				}
			}
			&:hover {
				.col-content {
					transition: $transition;
					background-color: $color-gray;
				}
			}
		}
		// Photo bacground
		&.cards__card--photo {
			background-size: cover;
			background-position: center; 
			h2, h3, p {
				color: $color-white;
			}
			.col-content {
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1d2748+0,1d2748+100&0.9+50,0+90 */
				background: -moz-linear-gradient(45deg, rgba(29,39,72,0.9) 0%, rgba(29,39,72,0.9) 50%, rgba(29,39,72,0) 90%, rgba(29,39,72,0) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(45deg, rgba(29,39,72,0.9) 0%,rgba(29,39,72,0.9) 50%,rgba(29,39,72,0) 90%,rgba(29,39,72,0) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(45deg, rgba(29,39,72,0.9) 0%,rgba(29,39,72,0.9) 50%,rgba(29,39,72,0) 90%,rgba(29,39,72,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e61d2748', endColorstr='#001d2748',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
			}
			&:hover {
				.col-content {
					transition: $transition;
					background-color: rgba($color-darkgray, 0.5);
				}
			}
			.cards__card__img {
				svg {
					path, polygon {
						fill: #fff;
					}
				}
			}
		}
        // card with image or icon on the top
        &.cards__card--img {
            .cards__card__img {
                margin: -2rem -2rem 0rem -2rem;
                min-height: 10rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
		}
	
		// Photo bgs
		&.cards__card--bg-1 .col-content {
			background-image: url(/img/card-1.jpg);
		}
		&.cards__card--bg-2 .col-content {
			background-image: url(/img/card-2.jpg);
		}
		&.cards__card--bg-3 .col-content {
			background-image: url(/img/card-3.jpg);
		}
		&.cards__card--bg-4 .col-content {
			background-image: url(/img/card-4.jpg);
		}
		&.cards__card--bg-5 .col-content {
			background-image: url(/img/card-5.jpg);
		}
		&.cards__card--bg-6 .col-content {
			background-image: url(/img/card-6.jpg);
		}
		&.cards__card--bg-7 .col-content {
			background-image: url(/img/card-7.jpg);
		}
		&.cards__card--bg-8 .col-content {
			background-image: url(/img/card-8.jpg);
		}
		&.cards__card--bg-9 .col-content {
			background-image: url(/img/card-9.jpg);
		}
		&.cards__card--bg-10 .col-content {
			background-image: url(/img/card-10.jpg);
		}
		&.cards__card--bg-11 .col-content {
			background-image: url(/img/card-11.jpg);
		}
		&.cards__card--bg-12 .col-content {
			background-image: url(/img/card-12.jpg);
		}
		&.cards__card--bg-13 .col-content {
			background-image: url(/img/card-13.jpg);
		}
		&.cards__card--bg-14 .col-content {
			background-image: url(/img/card-14.jpg);
		}
		&.cards__card--bg-15 .col-content {
			background-image: url(/img/card-15.jpg);
		}
		&.cards__card--bg-16 .col-content {
			background-image: url(/img/card-16.jpg);
		}
	}
    // Options
	&.cards--gridlines {
		box-shadow: $shadow-large;
		overflow: hidden;
		.cards__w {
			position: relative;
			margin-right: -3px;
			margin-bottom: -3px;
		}
		.cards__card {
			border-right: 3px solid $color-gray;
			border-bottom: 3px solid $color-gray;
			.col-content {
				box-shadow: none !important;
			}
		}
		&.cards--eq {
			@include media(tablet) {
				.cards__w {
					display: flex;
					flex-wrap: wrap;
					flex: 1;
				}
			}
		}
	}
	&.cards--nogutter {
		margin-right: 0rem;
		margin-left: 0rem;
		.cards__card {
			padding: 0 !important;
			margin: 0;
		}
	}
	&.cards--eq {
		@include media(tablet) {
			display: flex;
			flex-wrap: wrap;
			.cards__card {
				display: flex;
				&:last-of-type {
					flex: 1;
				}
			}
		}
	}
	&.cards--sticky {
		.cards__card {
			.col-content {
				position: relative;
				padding-bottom: 5rem !important;
			}
			.btn {
				position: absolute;
				bottom: 2rem;
				left: 2rem;
				right: 2rem;
				white-space: nowrap;
				overflow: hidden;
			}
		}
    }
}

