/*

    Forms

*/

.form, .hs-form {
    p {
        font-size: 1rem;
    }
    &.form--on {
        .form__action {
            transform: translateY(0);
            opacity: 1;
            transition: $transition;
        }
        .form__bar {
            opacity: 100 !important;
        }
    }
    &.form--done .form__action {
        .btn--submit {
            color: $color-white;
            pointer-events: auto;
            background-color: $color-red;
        }
    }
    &.form--completed {
        .poppins__fields {
            background-color: $color-green;
        }
        fieldset {
            display: none;
        }
        .form__action {
            display: none;
        }
        .form__completed {
            display: block;
            opacity: 1;
            z-index: auto !important;
            transition: $transition;
            transform: translateY(0);
        }
    }
    .form__input--direct p {
        color: $color-textmeta;
        font-size: 1rem;
    }
    .form__document {
        padding: 2rem;
        background-color: $color-white;
        box-shadow: $shadow;
        margin-bottom: 1rem;
        h3 {
            margin-top: 0;
        }
        p {
            margin-bottom: 1rem;
        }
    }
    .form__input {
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
        transition: $transition;
        position: relative;
        // Label
        label {
            font-weight: 700;
            line-height: 3rem;
            font-size: 0.85rem;
            cursor: pointer;
        }
        // Basic inputs
        input, textarea, select {
            display: block;
            font-size: 1rem;
            width: 100%;
            box-sizing: border-box;
            line-height: 3rem;
            padding: 0 1rem;
            box-shadow: $shadow;
            border: 0;
            color: $color-darkgray;
            border-radius: $radius;
            background-color: $color-white;
            &:active, &:focus {
                outline: none;
                border-color: $color-primary;
            }
            &::-webkit-input-placeholder {
                color: $color-textmeta;
            }
            &::-moz-placeholder {
                color: $color-textmeta;
            }
            &:-ms-input-placeholder {
                color: $color-textmeta;
            }
            &:-moz-placeholder {
                color: $color-textmeta;
            }
        }
        // Select box
        select {
            -webkit-appearance: none;
            color: $color-textmeta;
            &:valid {
                color: $color-text;
            }
        }
        // Textarea
        textarea {
            -webkit-appearance: none;
            line-height: 1.5rem;
            padding: 1rem;
        }
        // Date 
        input[type="date"] {
            height: 3rem;
            -webkit-appearance: none;
            color: $color-textmeta;
            width: 100%;
            @include media(mobile) {
                &:before {
                    content: attr(placeholder) !important;
                    color: $color-textmeta;
                    margin-right: 0.25rem;
                }
                &:focus:before,
                &:valid:before {
                    content: "";
                }
            }
        }
        // Radio
        input[type="radio"] {
            position: absolute;
            //top: -99999em;
            opacity: 0;
            left: -99999em;
            & + label {
                position: relative;
                padding-left: 2.5rem;
                display: inline-block;
                line-height: 1.5rem;
                margin-right: 1rem;
                &:before {
                    content: " ";
                    position: absolute;
                    border-radius: 1rem;
                    display: block;
                    width: 1.5rem;
                    height: 1.5rem;
                    left: 0;
                    top: 0;
                    box-shadow: $shadow;
                    background-color: $color-white;
                }
                &:after {
                    content: " ";
                    position: absolute;
                    border-radius: 1rem;
                    display: block;
                    width: 0.5rem;
                    height: 0.5rem;
                    left: 0.5rem;
                    top: 0.5rem;
                    background-color: $color-red;
                    opacity: 0;
                }
            }
            &:checked + label:after {
                opacity: 1;
            }
        }
        // Checkbox
        input[type="checkbox"] {
            position: absolute;
            //top: -99999em;
            opacity: 0;
            left: -99999em;
            & + label {
                position: relative;
                padding-left: 2.5rem;
                display: inline-block;
                line-height: 1.5rem;
                margin-right: 1rem;
                &:before {
                    content: " ";
                    position: absolute;
                    display: block;
                    width: 1.5rem;
                    height: 1.5rem;
                    left: 0;
                    top: 0;
                    box-shadow: $shadow;
                    background-color: $color-white;
                    border-radius: 4px;
                }
                &:after {
                    content: " ";
                    position: absolute;
                    display: block;
                    width: 0.5rem;
                    height: 0.5rem;
                    left: 0.5rem;
                    top: 0.5rem;
                    background-color: $color-red;
                    opacity: 0;
                }
            }
            &:checked + label:after {
                opacity: 1;
            }
        }
        input[type="text"] {
            &:after {
                content: attr(placeholder);
                position: absolute;
                right: 1rem;
            }
        }
        // Stripe
        .StripeElement {
            box-shadow: $shadow;
            background-color: $color-white;
            padding: 1rem;
        }
        // Input with button
        &.form__input--button {
            display: flex;
            input {
                border-radius: 4px 0 0 4px;
            }
            .btn {
                white-space: nowrap;
                border-radius: 0 4px 4px 0;
            }
        }
        // Fancy picking
        &.form__input--fancy {
            background-color: $color-white;
            box-shadow: $shadow;
            display: flex;
            align-items: stretch;
            .form__fancy {
                flex: 1;
                label {
                    margin: 0;
                    padding: 1rem 1rem 1rem 3.5rem;
                    display: block;
                    box-sizing: border-box;
                    height: 100%;
                    &:before {
                        left: 1rem;
                        top: 1.25rem;
                        background-color: $color-gray;
                        box-sizing: border-box;
                        border: 1px solid rgba($color-darkgray, 0.1);
                    }
                    &:after {
                        top: 1.75rem;
                        left: 1.5rem;
                    }
                }
                p {
                    font-size: 0.85rem;
                    line-height: 1.5;
                    margin: 0;
                    color: $color-darkgray;
                    strong {
                        display: block;
                    }
                }
                & + .form__fancy {
                    border-left: 3px solid $color-gray;
                }
                input:checked + label {
                    background-color: $color-green;
                    &:before {
                        border-color: transparent;
                    }
                }
            }

           
        }
        > i.material-icons {
            position: absolute;
            right: 1rem;
            top: 0;
            line-height: 3rem;
            vertical-align: text-bottom;
            color: $color-textmeta;
            pointer-events: none;
            &.material-icons--error {
                color: $color-red;
            }
            &.material-icons--valid {
                color: $color-green;
                display: none;
            }
            &.material-icons--ok {
                display: block;

            }
        }
        .form__directions {
            font-size: 0.85rem;
            color: $color-textmeta;
        }
        .form__error {
            color: $color-red;
            font-size: 0.85rem;
        }
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    // Conversational
    &.form--convers {
        .form__input input, .form__input textarea, .form__input select, .form__input input + .material-icons, .form__input select + .material-icons {
            opacity: 0.35;
            transition: $transition;
        }
        .form__input input:valid, .form__input textarea:valid, .form__input select:valid, .form__input input:valid + .material-icons, .form__input select:valid + .material-icons {
            opacity: 1;
            transition: $transition;
        }
        .form__input input:focus, .form__input textarea:focus, .form__input select:focus, .form__input input:focus + .material-icons, .form__input select:focus + .material-icons {
            opacity: 1;
            transition: $transition;
        }
        .form__input input:valid + .material-icons {
            color: $color-green;
        }
    }
    // Wizard
    &.form--wizard {
        .btn--submit {
            display: none;
        }
        .btn--next {
            background-color: $color-white;
            color: $color-primary;
        }
        &.form--lastset {
            .btn--submit {
                display: inline-block;
            }
            .btn--next {
                display: none;
            }
        }
        &.form--firstset {
            .btn--back {
                display: none;
            }
        }
        &.form--setrequired {
            .btn--next {
                pointer-events: none;
                background-color: $color-gray;
                color: $color-textmeta;
            }
        }
        fieldset {
            transition: $transition;
            transform: translateY(-2rem);
            //visibility: hidden;
            height: 0;
            overflow: hidden;
            opacity: 0;
            pointer-events: none;
            
        }
        fieldset.active {
            transition: $transition;
            transform: translateY(0);
            visibility: visible;
            height: auto;
            overflow: visible;
            opacity: 1;
            pointer-events: all;
        }
    }
    // Other
    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
        
    }
    .form__completed {
        display: none;
        text-align: center;
        margin: 4rem 0;
        color: $color-white;
        transition: $transition;
        opacity: 0;
        transform: translateY(-2rem);
        z-index: -1;
        .material-icons {
            background-color: $color-blue;
            color: $color-white;
            margin-bottom: 1.5rem;
        }
        h1 {
            color: $color-white;
            margin-bottom: 2rem;
        }
        p {
            font-size: 0.84rem;
            color: $color-textmeta;
        }
    }
    .form__action {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 3rem;
        padding: 1rem 4rem 4rem 4rem;
        -webkit-appearance: none;
        background: -moz-linear-gradient(45deg, rgba(29,39,72,0.9) 0%, rgba(29,39,72,0.9) 50%, rgba(29,39,72,0) 90%, rgba(29,39,72,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(45deg, rgba(29,39,72,0.9) 0%,rgba(29,39,72,0.9) 50%,rgba(29,39,72,0) 90%,rgba(29,39,72,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(0deg, rgba($color-gray,1) 0%,rgba($color-gray,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e61d2748', endColorstr='#001d2748',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        .btn {
            float: right;
            margin-left: 1rem;
        }
        .btn--submit {
            border: 0;
            color: $color-textmeta;
            transition: $transition;
            pointer-events: none;
            outline: none;
            position: relative;
            z-index: 10;
            background-color: $color-white;
        }
        .form__bar {
            opacity: 0;
            width: 100%;
            transition: $transition;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
        }
        .form__step {
            line-height: 2.5rem;
        }
        .form__progress {
            background: rgba($color-darkgray, 0.15);
            width: 0%;
            overflow: hidden;
            transition: $transition;
            color: $color-white;
            height: 0.5rem;
            span {
                display: block;
                white-space: nowrap;
                font-weight: 700;
            }
        }
    }
}

.hs-form {
    margin-bottom: 2rem;
    fieldset {
        max-width: none;
    }
    .hs-form-field {
        margin-bottom: 0.5rem;
        label {
            line-height: 1.5rem !important;
        }
        .input {
            margin-right: 0 !important;
            input {
                width: 100% !important;
            }
        }
    }
    .hs-submit {
        transform: translateY(0);
        opacity: 1;
        transition: $transition;
        right: 0;
    }
    .hs-button {
        @extend .btn;
        background: $color-white;
        color: $color-green;
    }
    .hs_recaptcha {
        padding-top: 1rem;
    }
    .hs-error-msgs {
        margin: 0.5rem 0 0 0;
        li {
            font-size: 0.85rem;
            label {
                color: $color-red;
            }
        }
    }
}

.submitted-message {
    position: absolute;
    font-weight: 700;
    text-align: center;
    width: 100%;
    left: 0;
    top: 0;
    vertical-align: middle;
    top: 50%;
    color: $color-primary;
}


// Datepicker (Flatpickr)

.flatpickr-calendar  {
	padding: 1rem;
	box-shadow: $shadow-over;
    border-radius: 0;
    width: 18rem;
	div, span, input {
		font-size: 0.85rem;
	}
	.flatpickr-current-month {
		.cur-month {
			font-weight: 800;
			color: $color-darkgray;
		}
		.cur-year {
			font-weight: 500;
			color: $color-textmeta;
		}
	}
	.flatpickr-weekdays {
		height: auto;
		margin-top: 0.5rem;
		.flatpickr-weekday {
			font-weight: 500;
			color: $color-textmeta;
			height: 2rem;
			line-height: 2rem;
			text-transform: uppercase;
			font-size: 0.65rem;
		}
	}
	.flatpickr-rContainer, .flatpickr-days, .dayContainer {
		width: 16rem;
		min-width: 16rem;
        max-width: 16rem;
	}
	.flatpickr-day {
		color: $color-darkgray;
		max-width: none;
		box-sizing: border-box;
		height: 2rem;
		line-height: 2.1;
		border-radius: 0;
		
		&.today {
			border-color: transparent;
			font-weight: 800;
			box-shadow: $shadow;
			&:hover {
				color: $color-darkgray;
			}

		}
		&.selected {
			font-weight: 800;
			background-color: $color-green;
			color: $color-white;
			border-color: transparent;
			box-shadow: $shadow;
		}
		&.prevMonthDay, &.nextMonthDay {
			color: $color-textmeta;
		}
		&:hover {
			background-color: $color-gray;
			border-color: rgba($color-darkgray, 0.065);
			box-shadow: $shadow;
		}
	}
	&:after, &:before {
		display: none;
	}
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .form {
        .form__input {
            input, textarea, select {
                font-size: 1rem;
            }
        }
    }
}