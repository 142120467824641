.btn {
	font-size: 1rem;
	line-height: 2.5em;
	display: inline-block;
	padding: 0 1.5em;
	@include font-sans;
	font-weight: 900 !important;
	transition: $transition;
	box-sizing: border-box;
	background-color: $color-primary;
	box-shadow: $shadow;
	position: relative;
	border: 0;
    border-radius: 4px;
    white-space: nowrap;
    text-align: center;
    color: $color-white;
    &:focus {
        outline:0;
    }
	&:hover {
        box-shadow: $shadow-over;
        //-webkit-filter: blur(0);
        transform: translateZ(0);
        transform: scale(1.032);
		transition: $transition;
	}
	i.material-icons {
        vertical-align: middle;
        position: relative;
        margin-top: -3px;
        color: $color-text;
    }
    &:link, &:visited {
        color: $color-white;
    }
    &input.btn {
        background: $color-white;
    }
    &.btn--secondary {
        background-color: $color-textmeta;
    }
    &.btn--green {
        background-color: $color-green;
        color: $color-blue;
        &:link, &:visited, i.material-icons {
            color: $color-blue;
        }
    }
    &.btn--red {
        background-color: $color-red;
        &:link, &:visited, i.material-icons {
            color: $color-white;
        }
    }
    &.btn--blue {
        background-color: $color-blue;
        &:link, &:visited, i.material-icons {
            color: $color-white;
        }
    }
    &.btn--white {
        color: $color-green;
        background-color: $color-white;
        &:link, &:visited, i.material-icons {
            color: $color-green;
        }
    }
    &.btn--link {
        background-color: transparent;
        color: $color-textmeta;
        box-shadow: none;
        i {
            color: $color-textmeta;
        }
        &:hover {
            color: $color-red;
            i {
                color: $color-red;
            }
        }
    }
    &.btn--filter{
        border-radius: 2rem;
        font-size: .65rem;
        margin-left: .5rem !important;
    }
    &.btn--small{
        font-size: .8rem;
	    line-height: 1.5em;
	    padding: 0 .5em;
    }
    span{
        &.icon{
            position: relative;
            top: 7px;
            right: -15px;
        }
    }
}

.card--dark {
    .btn {
        color: $color-text;
        &:link, &:visited {
            color: $color-text;
        }
    }
}

.btn + .btn {
	margin-left: 1rem;
}