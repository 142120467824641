.accordion__item{
    border-bottom: 3px solid $color-blue;
    dt{
        padding: 1rem 0;
        position: relative;
        cursor: pointer;
        h3{
            margin: 0 3rem 0 0;
            color: $color-blue;
        }
        .accordion__item--plus{
            width: 3rem;
            height: 3.5rem;
            position: absolute;
            border-radius: 10px;
            cursor: pointer;
            text-align: center;
            font-size: 2rem;
            color: $color-blue;
            right: 0;
            top: 0rem;
            background-image: url(/img/icon-plus.svg);
            background-position: center;
            background-size: 48px;
            background-repeat: no-repeat;
        }
        &.Zebra_Accordion_Expanded{
            .accordion__item--plus{
                background-image: url(/img/icon-less.svg);
            }
        }
    }
    dd{
        margin: 0;
        padding: 0 0 2rem 0;
        overflow: hidden;
        height: auto;
        max-width: 36rem;
        p{
            margin-top: 0;
            &:empty {
                display: none;
            }
        }
        ul, ol{
            margin: 1rem 0;
            li{
                ul, ol{
                    padding-left: 1rem;
                    margin: 0;
                }
            }
        }
    }
}